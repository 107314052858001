import React from "react";

type Props = {
  height: 10 | 20 | 30 | 40 | 50;
};

const Spacer: React.FC<Props> = ({ height = 0 }) => {
  return <div style={{ height }} />;
};

export default Spacer;
