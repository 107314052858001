import { createTheme, adaptV4Theme } from "@mui/material";

export default createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#5cbc63",
        contrastText: "#fff",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      h2: {
        fontWeight: 700,
        fontFamily: "CeraPro-Bold",
      },
      h3: {
        fontWeight: 700,
        fontFamily: "CeraPro-Bold",
      },
      h4: {
        fontWeight: 400,
        lineHeight: 1.3,
      },
      h5: {
        fontWeight: 600,
        color: "#454040",
      },
      fontFamily: "CeraPro-Regular",
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: "none",
          fontWeight: 700,
        },
        contained: {
          boxShadow: "none",
        },
        containedSizeLarge: {
          fontSize: 20,
        },
        sizeLarge: {
          fontSize: 20,
        },
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: "0.6em",
        },
      },
    },
  })
);

export const Colors = {
  primary: "#5cbc63",
};
