import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import Spacer from "../components/Spacer";

const Contact: React.FC = () => {
  useEffect(() => {
    document.body.classList.add("bg--dark");

    return () => {
      document.body.classList.remove("bg--dark");
    };
  }, []);

  return (
    <section className="min-height">
      <Spacer height={50} />
      <Spacer height={50} />
      <Spacer height={50} />
      <Spacer height={50} />
      <Spacer height={50} />
      <Typography variant="h3" gutterBottom>
        SUPPORT ENQUIRIES<span className="text--primary">.</span>
      </Typography>
      <Typography variant="h5" className="text--gray">
        Tech support: <span className="text--primary">help@10ten10.fit</span>
      </Typography>
      <Typography variant="h5" className="text--gray">
        Account support:{" "}
        <span className="text--primary">admin@10ten10.fit</span>
      </Typography>
      <Spacer height={50} />
      <Typography variant="h3" gutterBottom>
        PARTNERSHIP ENQUIRIES<span className="text--primary">.</span>
      </Typography>
      <Typography variant="h5">
        We work with world leading health professionals. If you wish to partner
        with us, please contact us here:{` `}
        <span className="text--primary">info@10ten10.fit</span>
      </Typography>
      <Spacer height={50} />
      <Typography variant="h3" gutterBottom>
        PRESS ENQUIRIES<span className="text--primary">.</span>
      </Typography>
      <Typography variant="h5">
        <span className="text--primary">info@10ten10.fit</span>
      </Typography>
    </section>
  );
};

export default Contact;
