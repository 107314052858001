import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Lottie from "lottie-react";
import React from "react";

import success from "../assets/lottie/success.json";
import error from "../assets/lottie/error.json";

type Props = {
  open: boolean;
  onClose: () => void;
  variant: "success" | "error";
  text: string;
};

const ApiResponseModal: React.FC<Props> = ({
  open,
  onClose,
  variant,
  text,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{variant === "success" ? "Great!" : "Oops..."}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <Box justifyContent="center" display="flex">
          <Lottie
            animationData={variant === "success" ? success : error}
            loop={false}
            style={{ width: 300, height: 250 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiResponseModal;
