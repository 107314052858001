import { Box, Typography } from "@mui/material";
import React from "react";

export type CustomerWithLostWeightLabelProps = {
  name: string;
  weightLoss: number;
  image: string;
  color: string;
};

const CustomerWithLostWeightLabel: React.FC<CustomerWithLostWeightLabelProps> = ({
  name,
  weightLoss,
  image,
  color,
}) => {
  return (
    <Box position="relative">
      <img
        src={image}
        height={400}
        width={"100%"}
        alt=""
        className="is-small-image"
        style={{ objectFit: "contain" }}
      />
      <Box
        position="absolute"
        bottom={100}
        left={0}
        px={4}
        py={1}
        bgcolor={color}
        zIndex="2"
      >
        <Typography variant="h5" color="textSecondary">
          {name}
        </Typography>
        <Typography variant="h6" align="left" style={{ color: "white" }}>
          <span className="bold">- </span>
          {weightLoss}kg
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomerWithLostWeightLabel;
