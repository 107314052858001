import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "./Logo";
import "./Navbar.scss";
import { motion } from "framer-motion";
import contactLogo from "../assets/compressed/logo_with_text_transparent_white.png";
import { Menu } from "@mui/icons-material";
import useIsMobile from "../hooks/isMobile";

const links = [
  {
    link: "/trainer",
    text: "For trainers",
  },
  {
    link: "/consumers",
    text: "Lose weight",
  },
  {
    link: "/join",
    text: "Join",
  },
  {
    link: "/contact",
    text: "Contact us",
  },
];

const Navbar: React.FC = () => {
  const location = useLocation();

  const isContactPage = location.pathname === "/contact";

  const isMobile = useIsMobile();

  const animate = getAnimate(location.pathname, isMobile);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <AppBar className="Navbar" position="static" elevation={0}>
      <Toolbar style={{ minHeight: 104 }}>
        <motion.div animate={animate}>
          <NavLink to="/">
            {isContactPage ? (
              <img alt="" src={contactLogo} width={100} />
            ) : (
              <Logo />
            )}
          </NavLink>
        </motion.div>
        <div className="grow" />
        {isMobile ? (
          <IconButton onClick={() => setIsOpen(true)} size="large">
            <Menu />
          </IconButton>
        ) : (
          links.map((link) => (
            <NavLink key={link.link} to={link.link}>
              <Typography>{link.text}</Typography>
            </NavLink>
          ))
        )}
        <a
          href="https://app.10ten10.fit/login"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="outlined" color="primary">
            Login
          </Button>
        </a>
      </Toolbar>
      <Drawer className="Navbar" open={isOpen} onClose={() => setIsOpen(false)}>
        <div style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
          {[{ text: "Home", link: "/" }].concat(links).map((link) => (
            <NavLink
              exact
              key={link.link}
              to={link.link}
              onClick={() => setIsOpen(false)}
            >
              <Typography>{link.text}</Typography>
            </NavLink>
          ))}
        </div>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;

const getAnimate = (path: string, isMobile: boolean) => {
  switch (path) {
    case "/contact":
      return {
        scale: isMobile ? 3 : 5,
        x: isMobile ? window.innerWidth / 2 - 100 : 150,
        y: 150,
      };
    case "/join":
      return {
        x: isMobile ? window.innerWidth / 2 - 75 : window.innerWidth / 2 - 50,
        y: 140,
        scale: isMobile ? 2 : 3,
      };
    default:
      return { scale: 1, x: 0, y: 0 };
  }
};
