import React from "react";
import logo from "../assets/compressed/logo_transparent.png";
import simpleLogo from "../assets/compressed/logo_black.png";

type Props = {
  variant?: "nav" | "simple";
};

const Logo: React.FC<Props> = ({ variant = "nav" }) => {
  return variant === "nav" ? (
    <img
      src={logo}
      height={100}
      width={100}
      alt="Logo"
      style={{ objectFit: "contain" }}
    />
  ) : (
    <img
      src={simpleLogo}
      height={50}
      width={50}
      alt="Logo"
      style={{ objectFit: "contain", borderRadius: 25 }}
    />
  );
};

export default Logo;
