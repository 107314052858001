import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { format } from "date-fns";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { RouteComponentProps } from "react-router";
import { ContactTrainerDialog } from "../components/ContactTrainerDialog";
import { Course } from "./Courses";

import dummyImg from "../assets/compressed/shutterstock_561791941-min.jpg";

type CourseDetails = Course & {
  details: {
    price: number;
    currency: string;
  };
};

const paperPadding = 16;

export const CoursePage: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
  history,
}) => {
  const queryCache = useQueryClient();
  const courseId = match.params.id;
  const [open, setOpen] = useState(false);

  const { data } = useQuery<CourseDetails>(
    ["courses", courseId],
    async () => {
      const res = await fetch(
        "https://tententen-backend-vsrz3.ondigitalocean.app/api/course/upcomming?offset=0&limit=100&key=234jhk78s6dfDSA234jhasdjhg324g8970jvj456g"
      );
      const data: { result: string; data: CourseDetails[] } = await res.json();

      const course = data.data.find(
        (course) => course.details.id === parseInt(courseId, 10)
      );

      return (
        course ?? {
          participants: [],
          details: {
            id: -1,
            active: false,
            currency: "€",
            name: "John",
            price: 4,
            start_date: "2021-12-12 14:00:00",
            country_id: 1,
          },
          trainer: {
            id: 2,
            name: "Rachel",
            profile_picture: "",
          },
        }
      );
    },
    {
      initialData: queryCache
        .getQueryData<CourseDetails[]>("courses")
        ?.find((course) => course.details.id === parseInt(courseId, 10)),
    }
  );

  let date;

  if (data?.details.start_date) {
    date = format(new Date(data.details.start_date), "EEEE d. MMMM, yyyy");
  }

  return (
    <section className="min-height">
      <Box marginBottom={2}>
        <Button
          color="primary"
          onClick={() => history.goBack()}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
      </Box>
      <img
        src={data?.trainer?.background_picture ?? dummyImg}
        style={{
          height: 250,
          width: "100%",
          objectFit: "cover",
          objectPosition: "top",
          marginBottom: 30,
          filter: "saturate(0.5)",
          borderRadius: 4,
        }}
        alt="Trainer and participants in gym excercising"
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper variant="outlined" style={{ padding: paperPadding }}>
            <Typography variant="h3" gutterBottom>
              {data?.details.name}
            </Typography>
            <Typography>Course begins on {date}</Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={3}
            >
              {/* <Typography variant="h6" color="textSecondary">
                {data?.details.price ?? "500"}{" "}
                <span>{data?.details.currency ?? "€"}</span>
      </Typography> */}
              <Button
                color="primary"
                onClick={() => setOpen(true)}
                variant="contained"
              >
                Join course
              </Button>
            </Box>
            <Box mt={3}>
              <Typography whiteSpace={"break-spaces"}>
                {data?.details.description ??
                  "The trainer didn't set a description yet."}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            style={{ padding: paperPadding, marginBottom: 10 }}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={data?.trainer.profile_picture}
                onClick={() => {
                  if (data?.trainer.slug) {
                    history.push(`/trainers/${data?.trainer.slug}`);
                  }
                }}
                style={{
                  width: 56,
                  height: 56,
                  marginRight: 12,
                  cursor: data?.trainer.slug ? "pointer" : "default",
                }}
              >
                {data?.trainer.name.substr(0, 1)}
              </Avatar>
              <Typography style={{ fontWeight: 600 }}>
                {data?.trainer.name}
              </Typography>
              <Box flexGrow={1} />
            </Box>
          </Paper>
          {/* 
          <Paper variant="outlined">
            <List subheader={<ListSubheader>Participants</ListSubheader>}>
              {data?.participants.length === 0 && (
                <ListItem>
                  <ListItemText primary="No participants yet" />
                </ListItem>
              )}
              {data?.participants.map((participant) => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={participant.profile_picture}>
                      {participant.name.substr(0, 1)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={participant.name}></ListItemText>
                </ListItem>
              ))}
            </List>
              </Paper> */}
        </Grid>
      </Grid>
      <ContactTrainerDialog
        open={open}
        onClose={() => setOpen(false)}
        trainerId={data?.trainer.id}
        trainerName={data?.trainer.name}
        courseId={data?.details.id}
      />
    </section>
  );
};
