import {
  Box,
  Grid,
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Button,
  List,
} from "@mui/material";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { ITrainerDetails, linkTypes } from "../types/types";
import InstagramGallery from "./InstagramGallery";

import img1 from "../assets/trainer_8_instagrams/1.jpg";
import img2 from "../assets/trainer_8_instagrams/2.jpg";
import img3 from "../assets/trainer_8_instagrams/3.jpg";
import img4 from "../assets/trainer_8_instagrams/4.jpg";
import { ContactTrainerDialog } from "./ContactTrainerDialog";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

const ProfileDetails: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ match }) => {
  const [open, setOpen] = useState(false);

  const trainerId = match.params.id;

  const { data } = useQuery(["trainers", trainerId], async () => {
    const response = await fetch(
      `https://tententen-backend-vsrz3.ondigitalocean.app/api/details_trainer/${trainerId}`,
      {
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMjJmNjQ3Njk5MzFhNTc0MjRiNjM5MmZlZGViYTBlZTJlMDU0YTY2OGQ2YzVjOWI4NGFkZGFhZGYzNTg5NDg5ODBhMzhjMzdjZGUyNDZhMjgiLCJpYXQiOiIxNjE1MzczMTg4LjA4NTgwOSIsIm5iZiI6IjE2MTUzNzMxODguMDg1ODE4IiwiZXhwIjoiMTY0NjkwOTE4OC4wMTgwOTAiLCJzdWIiOiI4Iiwic2NvcGVzIjpbXX0.NPxWRGTvLquioGzAkiRWtoa-pZ25qp3THje5lfrCKM1K-kiefqkaFZ0d4ZvEfQkRxUbEZuukEWC5Poo9HA1uasZaNOuhA3ZPqxBPxgVQz59k8f1OdC8QEdtTDtDXRlb1giWueI_o_8491p8YQqesdgAiodaAjIUp-5oriwG5ynAqg7PeVuoEt-hKWStsEngncoT973oC0qDPUgjBWX7v2ZDFBxImuCPg4rfCe-3Y7lXzYpRLz7MjiqUYin-3Gz1dk92BvHjrXrKcdO_bTkWljAOrRfo86AWIarCW0gcbBvNVNsaHfamkS_z1GiL3RlT96SnbrErjQ29iyar4DxNHnchJOUAdC2zjJXxeTYjWBvhDBqEI1A6_NZ4x4gEsslClJbHDqZKUOjugBXYggH3YYlCYvLRy7tLsdoiooUVbL5wyQysXGE7NZc5eAyGWTPQB-cYpWLxF907sfcdZv1yWKY6kM4zbMpgkPj8_DQAVfykDoF2mVbYfDwO3e71BlDZvqUUeBPd85y8AmzGlEUODslfkC8gv__EW_0B2rdPdtqzkfGAgC9KQg-UrfVc6sY8pGBW-XCwqivVI4pE0Rk6MuRFQ6wq-hRq9XMty4op_gxNJekXI_dDxzqyuHAUxgKw-13GEZr_bt72f1gePhfjD6rbwm84o63f3I0YGZc0K2wE",
        },
      }
    );

    const data: { data: ITrainerDetails } = await response.json();

    return data.data;
  });

  const brandingInfo = data?.branding?.[0];

  return (
    <section className="min-height">
      <Box
        height={250}
        display="flex"
        position="relative"
        justifyContent="center"
        alignItems="center"
        bgcolor="black"
        mb={10}
        style={{
          backgroundImage: `url(${data?.background})`,
          backgroundSize: "cover",
        }}
      >
        <Box position="absolute" bottom={-50}>
          <Avatar
            src={data?.profile_picture}
            style={{ width: 150, height: 150, fontSize: 75 }}
          >
            {data?.name?.substr(0, 1)}
          </Avatar>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={5} xs={12}>
          <Paper
            variant="outlined"
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">{data?.name}</Typography>
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                style={{
                  backgroundColor: brandingInfo?.button_color,
                  color: brandingInfo?.font_color,
                }}
              >
                Contact
              </Button>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Typography>{data?.email}</Typography>
            </Box>
            <Box>
              {data?.urls?.map((url) => (
                <ListItem
                  key={url.id}
                  button
                  onClick={() =>
                    window.open(
                      !url.url.startsWith("https://")
                        ? "https://" + url.url
                        : url.url
                    )
                  }
                >
                  <ListItemIcon>
                    {linkTypes.find((link) => link.value === url.type)?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={url.url}
                    sx={{ overflowWrap: "break-word" }}
                  />
                </ListItem>
              ))}
            </Box>
          </Paper>
          <Paper variant="outlined" style={{ marginTop: 15 }}>
            <Box px={2} py={2}>
              <Typography variant="h6">My upcoming courses</Typography>
            </Box>
            <List>
              {data?.upcomming.map((upcomingCourse) => {
                const startDate = format(
                  new Date(upcomingCourse.start_date),
                  "EEEE, d. MMM, yyyy"
                );

                return (
                  <ListItem
                    button
                    key={upcomingCourse.id}
                    style={{ cursor: "pointer" }}
                    component={Link}
                    to={`/courses/${upcomingCourse.id}`}
                  >
                    <ListItemText
                      primary={upcomingCourse.name}
                      secondary={`Starts ${startDate}`}
                    />

                    <ChevronRight />
                  </ListItem>
                );
              })}
              {data?.upcomming.length === 0 && (
                <Typography ml={2} mb={2} color="textSecondary">
                  New courses coming soon. 😄
                </Typography>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Paper variant="outlined">
            <Box p={2}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
              >
                {brandingInfo?.logo && (
                  <Avatar
                    src={brandingInfo?.logo}
                    variant="rounded"
                    style={{ width: 125, height: 125 }}
                  />
                )}
                {brandingInfo?.company_name &&
                  brandingInfo?.company_name !== "null" && (
                    <Typography style={{ fontWeight: "bold" }}>
                      {brandingInfo?.company_name}
                    </Typography>
                  )}
              </Box>
              {brandingInfo?.tag_line && brandingInfo?.tag_line !== "null" && (
                <Typography
                  align="center"
                  gutterBottom
                  style={{ fontStyle: "italic" }}
                >
                  {brandingInfo?.tag_line}
                </Typography>
              )}
              <Box mt={4}>
                {data?.details?.split("\n").map((paragraph, i) => (
                  <Typography paragraph key={`p_${i}`}>
                    {paragraph}
                  </Typography>
                ))}
                <Box mt={5}>
                  <InstagramGallery
                    images={
                      trainerId === "rachel"
                        ? [
                            { url: img1, user_id: 8, id: -1 },

                            { url: img2, user_id: 8, id: -2 },
                            { url: img3, user_id: 8, id: -3 },
                            { url: img4, id: -4, user_id: 8 },
                          ]
                        : data?.instagrams
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <ContactTrainerDialog
        open={open}
        onClose={() => setOpen(false)}
        trainerId={data?.id}
        trainerName={data?.name}
      />
    </section>
  );
};

export default ProfileDetails;
