import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const Footer: React.FC = () => {
  return (
    <footer>
      <div style={{ padding: "20px 0", backgroundColor: "var(--lightgray)" }}>
        <section>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box>
              <Logo />
              <Typography
                variant="caption"
                style={{ display: "block", position: "relative" }}
              >
                Copyright © 2021 10TEN10 FIT LTD, All rights reserved.
              </Typography>
            </Box>
            <div>
              <Link to="/privacy" style={{ marginRight: 15 }}>
                Privacy
              </Link>
              <Link to="/terms" style={{ marginRight: 15 }}>
                Terms
              </Link>
              <Link to="/data-deletion">Data retention</Link>
            </div>
          </Box>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
