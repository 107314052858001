import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import ApiResponseModal from "../components/ApiResponseModal";
import RightCorner from "../components/RightCorner";
import Spacer from "../components/Spacer";

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const fields = [
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Phone no",
    value: "phone",
  },
  {
    name: "Where did you hear about us?",
    value: "hearAboutUs",
  },
  {
    name: "Enquiry",
    value: "description",
  },
  {
    name: "Social links",
    value: "socialLinks",
    helper: "Seperate by a new line",
  },
];

type Form = {
  name: string;
  email: string;
  phone: string;
  hearAboutUs: string;
  description: string;
  socialLinks: string;
  video_url_1: string;
  video_url_2: string;
};

const Join: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [form, setForm] = useState<Form>({
    name: "",
    email: "",
    phone: "",
    hearAboutUs: "Trainer",
    description: "",
    socialLinks: "",
    video_url_1: "",
    video_url_2: "",
  });
  const [country, setCountry] = useState<string>("uk");
  //const [videos, setVideos] = useState<FileList | null>();

  const [modal, setModal] = useState<{
    text: string;
    variant: "success" | "error";
    open: boolean;
  }>({
    text: "",
    variant: "success",
    open: false,
  });

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.get("modal_open")) {
      setIsVisible(true);
      history.replace({
        pathname: "/join",
      });
    }
  }, [location, history]);

  const { mutate: handleSubmit, isLoading } = useMutation(
    "submit",
    async () => {
      try {
        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("email", form.email);
        formData.append("phone", form.phone);
        formData.append("where", form.hearAboutUs);
        formData.append("inquiry", form.description);
        formData.append("social", form.socialLinks);
        formData.append("location", country);
        formData.append("video_url_1", form.video_url_1);
        formData.append("video_url_2", form.video_url_2);

        const res = await fetch(
          "https://tententen-backend-vsrz3.ondigitalocean.app/api/partnership",
          {
            method: "POST",
            body: formData,
          }
        );

        if (res.ok) {
          setModal({
            open: true,
            text: "We have received your request. You'll hear from us soon!",
            variant: "success",
          });
        } else {
          window.alert("You filled out the form incorrectly. Try again");
          setModal({
            open: true,
            text: "The form is filled out incorrectly. Try again",
            variant: "error",
          });
        }
      } catch (e) {
        setModal({
          open: true,
          text: "Something bad happened on the server. Try to fill out the form again otherwise contact us directly",
          variant: "error",
        });
        window.alert((e as Error).message);
      } finally {
        setIsVisible(false);
      }
    }
  );

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /*
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setVideos(event.target.files);
  };
  */

  return (
    <section className="min-height">
      <Spacer height={50} />
      <Spacer height={50} />
      <Spacer height={50} />
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <Typography variant="h3" gutterBottom>
          Want to lose <span className="text--primary">weight</span>?
        </Typography>
        <Typography variant="h4" className="text--gray" paragraph>
          Searching for a 10TEN10 group? Join a course or get in contact with a
          Trainer.
        </Typography>
        <Box display="flex">
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => history.push("/courses")}
          >
            Join course
          </Button>
          <Box ml={2}>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => history.push("/trainers")}
            >
              10TEN10 trainers
            </Button>
          </Box>
        </Box>

        <Spacer height={50} />
        <Spacer height={30} />
        <Typography variant="h3" gutterBottom>
          Are you a <span className="text--primary">Trainer</span>?
        </Typography>
        <Typography variant="h4" className="text--gray" gutterBottom>
          Looking to get your online platform started?{" "}
          <span
            className="text--primary underline cursor"
            onClick={() => history.push("/contact")}
          >
            Get in touch
          </span>{" "}
          and we will be in contact to discuss your application and get things
          set up!{" "}
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => setIsVisible(true)}
        >
          Apply now
        </Button>
      </motion.div>
      <RightCorner />
      <Dialog open={isVisible} onClose={() => setIsVisible(false)}>
        <Box position="absolute" top={5} right={5}>
          <IconButton onClick={() => setIsVisible(false)} size="large">
            <Close />
          </IconButton>
        </Box>
        <DialogTitle>
          <Typography variant="h5" gutterBottom>
            Apply for a partnership
          </Typography>
          <Typography variant="h6" style={{ color: "var(--gray)" }}>
            We are looking for excellent trainers who would like to drive
            10ten10 programs
          </Typography>
        </DialogTitle>
        <DialogContent>
          {fields.map((field, index) => {
            const value = form[field.value as keyof Form];

            if (field.value === "hearAboutUs") {
              return (
                <FormControl margin="normal" fullWidth>
                  <FormLabel>Where did you hear about us?</FormLabel>
                  <Select
                    value={value}
                    onChange={handleChange}
                    name={field.value}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="Trainer">Trainer</MenuItem>
                    <MenuItem value="Instagram">Instagram</MenuItem>
                    <MenuItem value="Facebook">Facebook</MenuItem>
                    <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                    <MenuItem value="TikTok">TikTok</MenuItem>
                    <MenuItem value="Search Engine (Google etc)">
                      Search Engine (Google etc)
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              );
            }
            return (
              <TextField
                autoFocus={index === 0}
                fullWidth
                variant="outlined"
                margin="normal"
                label={field.name}
                value={value}
                rows={index === 4 || index === 5 ? 4 : 0}
                multiline={index === 4 || index === 5}
                onChange={handleChange}
                name={field.value}
                helperText={field.helper}
              />
            );
          })}
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel variant="outlined">Location</InputLabel>
              <Select
                variant="outlined"
                label="Location"
                value={country}
                onChange={(event) => setCountry(event.target.value as string)}
              >
                <MenuItem value="uk">United Kingdom</MenuItem>
                <MenuItem value="nz">New Zealand</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* <Box
            mt={2}
            border="1px rgb(0,0,0,0.23) solid"
            borderRadius="4px"
            padding="10px 14px"
          >
          
            <input
              id="file_upload1"
              type="file"
              accept="video/*"
              onChange={handleFileUpload}
              multiple
              style={{ display: "none" }}
            />
            <label htmlFor="file_upload1">
              <Alert severity="info">
                Please upload sample workout videos. Provide two. Use shift key
                to select multiple.
              </Alert>
              <Box mt={1}>
                {videos ? (
                  <Box display="flex" alignItems="center">
                    <Check color="primary" />
                    <Typography style={{ marginLeft: 7.5 }}>
                      {videos.length} ready for upload.
                    </Typography>
                  </Box>
                ) : (
                  <Button component="span">Upload video</Button>
                )}
              </Box>
            </label>
                </Box> */}
          <Box mt={2}>
            <Alert severity="info" sx={{ fontSize: "1rem" }}>
              If possible, please provide links of footage with sample workout
              videos. It can be from YouTube or similiar platforms. (Not a
              requirement)
            </Alert>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.video_url_1}
            onChange={handleChange}
            name="video_url_1"
            label="Video link 1"
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.video_url_2}
            onChange={handleChange}
            name="video_url_2"
            label="Video link 2"
          />
        </DialogContent>
        <DialogActions>
          <Box px={2} width="100%" mt={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={
                Object.values(form).some((value) => !value) || isLoading
              }
              onClick={() => handleSubmit()}
            >
              Apply
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ApiResponseModal
        open={modal.open}
        onClose={() => setModal((prev) => ({ ...prev, open: false }))}
        text={modal.text}
        variant={modal.variant}
      />
    </section>
  );
};

export default Join;
