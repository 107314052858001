import { Box, Button, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import Spacer from "../components/Spacer";
import laptopAndPhone from "../assets/compressed/computer-min__cloundinary_resized.png";
import phone from "../assets/compressed/phone-min_compressed_resized.png";
import email from "../assets/compressed/10ten10_email-13-min.png";
import { Section } from "./Trainer";
import { RouteComponentProps } from "react-router-dom";
import useIsMobile from "../hooks/isMobile";

// improts
import peep from "../assets/full.mp4";
import home from "../assets/home_cloudinary.mp4";
import lookingAtScreen from "../assets/compressed/looking_at_screen_compressed__cloudinary.jpeg";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Home: React.FC<RouteComponentProps> = ({ history }) => {
  const isMobile = useIsMobile();

  return (
    <div>
      <section>
        <motion.div initial="hidden" animate="visible" variants={variants}>
          <Typography variant="h2" gutterBottom>
            10TEN10
          </Typography>
          <Typography gutterBottom variant="h4" color="primary">
            The first guaranteed weight loss solution, catered and packaged
            directly to the personal trainers through the use of a
            first-in-class online training platform.
          </Typography>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => history.push("/contact")}
          >
            Get in touch
          </Button>
        </motion.div>
        <Box mt={5} position="relative">
          <img
            className="is-large-image"
            src={lookingAtScreen}
            width={500}
            alt=""
            style={{ opacity: 0.7, borderRadius: "50% 20% / 20% 40%" }}
          />
          <div
            style={{
              position: isMobile ? "inherit" : "absolute",
              bottom: isMobile ? 0 : -200,
              right: 0,
            }}
          >
            {!isMobile ? (
              <motion.div
                animate={{ scale: 1.2, y: 50 }}
                transition={{ duration: 2 }}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item sm={"auto"} xs={12}>
                    <img
                      className="is-large-image"
                      src={laptopAndPhone}
                      alt=""
                      style={{ height: 500, objectFit: "cover" }}
                    />
                  </Grid>
                  <Grid item sm={"auto"} xs={12}>
                    <img
                      className="is-large-image"
                      src={phone}
                      alt="its a phone!"
                      style={{ height: 400, objectFit: "cover" }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            ) : null}
          </div>
        </Box>
      </section>
      <div style={{ height: !isMobile ? 200 : 50 }} />
      <div
        style={{
          backgroundColor: "rgb(247, 249, 252)",
          paddingBottom: 100,
          paddingTop: 70,
        }}
      >
        <section>
          <Spacer height={30} />
          <Typography variant="h3" gutterBottom>
            How it works
          </Typography>
          <Spacer height={30} />
          <img src={email} alt="" height={150} className="is-large-image" />
          <Spacer height={50} />
          <Typography
            variant="h5"
            color="primary"
            paragraph
            style={{ fontWeight: 500 }}
          >
            10TEN10 involves 10 people as one group, under one Personal Trainer
            losing 10% of their body weight over a 10 week period.
          </Typography>
          <Typography variant="h5" color="primary">
            Put simply, 10TEN10 is a 10 week Trainer led diet and exercise
            platform.
          </Typography>
          <Spacer height={50} />
          <Spacer height={50} />
        </section>
      </div>
      <div className="bg--dark" style={{ paddingBottom: 100, paddingTop: 70 }}>
        <section>
          <Section
            title="Focus on what you do best"
            description="Realise your full earning potential by delivering a hassle free weight loss solution to your clients & reach thousands more through the 10TEN10 technology solution."
            btnTitle="Trainer"
            onClick={() => history.push("/trainer")}
            isPrimary={true}
          >
            <Box display="flex" justifyContent="center">
              <video
                className="is-large-image"
                playsInline
                height={1000}
                src={home}
                loop={true}
                autoPlay={true}
                muted={true}
                style={{ borderRadius: 80 }}
              />
            </Box>
          </Section>
        </section>
      </div>
      <div
        className="bg--primary"
        style={{ paddingBottom: 100, paddingTop: 70 }}
      >
        <section>
          <Section
            title="A guaranteed weight loss solution"
            btnTitle="Lose weight"
            description="10TEN10 takes best practice in change behaviour, nutrition and exercise programming, delivered in a  highly effective, low–risk, easy to comprehend format."
            onClick={() => history.push("/consumers")}
            isPrimary={true}
            variant="contained"
          >
            <Box display="flex" justifyContent="center">
              <video
                className="is-large-image"
                playsInline
                height={500}
                src={peep}
                loop={true}
                autoPlay={true}
                muted={true}
              />
            </Box>
          </Section>
        </section>
      </div>
    </div>
  );
};

export default Home;
