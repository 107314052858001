import React from "react";
import { Facebook, Help, Instagram, Language } from "@mui/icons-material";

export enum LinkType {
  OTHER = 0,
  IG = 1,
  FB = 2,
  WEB = 3,
}

export type UserUrl = {
  created_at: string;
  id: number;
  type: LinkType;
  updated_at: string;
  url: string;
};

export const linkTypes: {
  name: string;
  value: LinkType;
  icon: JSX.Element;
}[] = [
  { name: "Other", value: LinkType.OTHER, icon: <Help /> },
  { name: "Facebook", value: LinkType.FB, icon: <Facebook /> },
  { name: "Instagram", value: LinkType.IG, icon: <Instagram /> },
  { name: "Website", value: LinkType.WEB, icon: <Language /> },
];

export type ICourse = {
  active: boolean;
  id: number;
  name: string;
  start_data: string;
};

export type ITrainer = {
  id: number;
  trainer: number;
  name: string;
  email: string;
  last_login_at: string;
  phone: number;
  weight: null;
  current_weight: null;
  height: number;
  birthday: string;
  activity_level: number;
  onboarding: number;
  countries_id: number;
  gender: string;
  details: string;
  background: string;
  profile_picture: string;
  email_verified_at: null;
  created_at: string;
  updated_at: string;
  stripe_id: null;
  trial_ends_at: null;
  urls: UserUrl[];
  instagrams: { id: number; user_id: number; url: string }[];
  branding: {
    id: number;
    trainer_id: number;
    font_color: string;
    background_color: string;
    button_color: string;
    tag_line: string;
    company_name: string;
    logo: string;
    logo_public_id: string;
    profile_picture: string;
    profile_picture_public_id: string;
    created_at: string;
    updated_at: string;
    slug: string;
  }[];
};

export interface ITrainerDetails extends ITrainer {
  upcomming: {
    id: number;
    name: string;
    price: number;
    currency: string;
    description?: string;
    start_date: string;
  }[];
}
