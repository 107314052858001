import { Dialog, DialogContent } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  src: string;
};

const DialogFullscreen: React.FC<Props> = ({ open, onClose, src }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <img
          src={src}
          alt="fullscreen"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DialogFullscreen;
