import React from "react";

const RightCorner: React.FC = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 20,
        height: 30,
        width: 30,
        backgroundColor: "var(--primary)",
        borderBottom: "solid 1px black",
      }}
    />
  );
};

export default RightCorner;
