import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Spacer from "../components/Spacer";
import { motion } from "framer-motion";
import { RouteComponentProps } from "react-router-dom";
import testVideo from "../assets/user.mov";

import calorieCounter from "../assets/compressed/calorieCounter-min_cloudinary.jpeg";
import guyInKitchen from "../assets/compressed/guy_in_kitchen-min_cloudinary.jpeg";
import girlInKitchen from "../assets/compressed/girl_in_kitchen-min_cloudinary.jpeg";

import femaleWorkoutHome from "../assets/compressed/female_workout_home-min.jpg";
import maleWorkoutHome from "../assets/compressed/male_workout_home-min.jpg";
import coupleInKitchen from "../assets/compressed/couple_in_kitchen-min.jpg";
import granny from "../assets/compressed/granny-min.jpg";
import CustomerWithLostWeightLabel, {
  CustomerWithLostWeightLabelProps,
} from "../components/CustomerWithLostWeightLabel";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const people: CustomerWithLostWeightLabelProps[] = [
  {
    name: "Jean",
    weightLoss: 9,
    color: "var(--primary)",
    image: calorieCounter,
  },
  {
    name: "Tony",
    weightLoss: 10,
    color: "var(--primary)",
    image: guyInKitchen,
  },
  {
    name: "Amy",
    weightLoss: 5,
    color: "var(--primary)",
    image: girlInKitchen,
  },
];

const Consumer: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <div>
      <motion.div initial="hidden" animate="visible" variants={variants}>
        <section>
          <Typography variant="h2" gutterBottom>
            <span className="text--primary">Achieve</span> and{" "}
            <span className="text--primary">maintain</span> results
          </Typography>
          <Typography variant="h4" gutterBottom>
            10TEN10 involves a personal trainer which gives you sustained access
            to coaching, education and support from start to finish. You have a
            qualified professional supporting you at every step.
          </Typography>
          <Grid container spacing={3}>
            {people.map((person) => (
              <Grid item sm={4} xs={12} key={person.name}>
                <CustomerWithLostWeightLabel {...person} />
              </Grid>
            ))}
          </Grid>
          <Spacer height={50} />
        </section>
      </motion.div>
      <div className="bg--dark" style={{ paddingBottom: 100, paddingTop: 70 }}>
        <section>
          <Typography variant="h2" gutterBottom>
            Manage your journey through your{` `}
            <span className="text--primary">personalised</span> online portal
          </Typography>
          <Typography variant="h4" gutterBottom>
            Every member of 10TEN10 has an individual login to track, record and
            manage their journey. Your Trainer will provide resources and
            actions to complete throughout your 10 weeks.
          </Typography>

          <Spacer height={30} />
          <div style={{ textAlign: "center" }}>
            <video
              className="is-large-image"
              playsInline
              src={testVideo}
              loop={true}
              autoPlay={true}
              muted={true}
              height={1000}
              style={{ borderRadius: 80 }}
            />
          </div>
        </section>
      </div>
      <div
        className="bg--primary"
        style={{ paddingBottom: 100, paddingTop: 70 }}
      >
        <section>
          <Typography variant="h2" gutterBottom>
            Be part of a{" "}
            <span className="text--secondary">supportive community</span>
          </Typography>
          <Box mb={5} mt={7}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Box mb={4}>
                  <Typography className="body-2" variant="body2">
                    10TEN10 involves a digital community to connect members of
                    your group to inspire and motivate you on your weight loss
                    journey
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => history.push("/courses")}
                >
                  Join a course
                </Button>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box
                  display="grid"
                  mb={2}
                  gridTemplateColumns="repeat(2, 1fr)"
                  gap={3}
                >
                  <img
                    width="100%"
                    src={maleWorkoutHome}
                    alt="male workout home"
                  />
                  <img
                    width="100%"
                    src={femaleWorkoutHome}
                    alt="female workout home"
                  />
                  <img
                    width="100%"
                    src={coupleInKitchen}
                    alt="couple in kitchen"
                  />
                  <img width="100%" src={granny} alt="couple in kitchen" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </section>
      </div>
    </div>
  );
};

export default Consumer;
