import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
  DialogActions,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import ApiResponseModal from "./ApiResponseModal";

interface ContactTrainerDialogProps {
  open: boolean;
  onClose: () => void;
  trainerName?: string;
  trainerId?: number;
  courseId?: number;
}

export const ContactTrainerDialog: React.FC<ContactTrainerDialogProps> = ({
  onClose,
  open,
  trainerName,
  trainerId,
  courseId,
}) => {
  const [email, setEmail] = useState("");
  const [why, setWhy] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [modal, setModal] = useState<{
    text: string;
    variant: "success" | "error";
    open: boolean;
  }>({
    text: "",
    variant: "success",
    open: false,
  });

  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://tententen-backend-vsrz3.ondigitalocean.app/api/lead/apply`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            about: why,
            name,
            phone,
            key: "245Fvgh5Shi54vFsdMZxhdDg324g8970jvj456g",
            misc: "",
            trainer_id: trainerId,
            course_id: courseId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        onClose();
        setModal({
          variant: "success",
          text: "The trainer will get back to you soon",
          open: true,
        });
      } else {
        setModal({
          variant: "error",
          text: "The form was filled in incorrectly. Try again",
          open: true,
        });
      }
    } catch (e) {
      setModal({
        variant: "error",
        text: "Something bad happened on the server. Perhaps try again or contact the trainer directly by email",
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Contact</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {courseId
              ? `Let trainer ${trainerName} that you're interested in joining. You'll
            be notified by email when ${trainerName} has seen your request.`
              : `Let trainer ${trainerName} that you're interested in joining a course or ask any question you want. You'll
            be notified by email when ${trainerName} has seen your request.`}
          </DialogContentText>
          <Box flexDirection="column" display="flex">
            <TextField
              margin="normal"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              margin="normal"
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              margin="normal"
              variant="outlined"
              multiline
              rows={3}
              value={why}
              onChange={(event) => setWhy(event.target.value)}
              label="How much weight would you like to lose?"
            />
            <TextField
              margin="normal"
              variant="outlined"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              placeholder="+45 12345678"
              label="Phone with leading country code"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress />}
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!email || !why}
            onClick={handleSend}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <ApiResponseModal
        open={modal.open}
        text={modal.text}
        variant={modal.variant}
        onClose={() =>
          setModal((prev) => ({
            ...prev,
            open: false,
          }))
        }
      />
    </Fragment>
  );
};
