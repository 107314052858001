import { Box } from "@mui/material";
import React, { useState } from "react";
import DialogFullscreen from "./DialogFullscreen";

type Props = {
  images?: { id: number; user_id: number; url: string }[];
};

const InstagramGallery: React.FC<Props> = ({ images }) => {
  const [fullscreenImg, setFullscreenImg] = useState("");

  return (
    <div>
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gap={2}
        border="solid 1px #eee"
      >
        {images?.map((image) => (
          <img
            key={image.id}
            onClick={() => setFullscreenImg(image.url)}
            src={image.url}
            alt="gallery"
            style={{
              height: 200,
              width: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        ))}
      </Box>
      <DialogFullscreen
        onClose={() => setFullscreenImg("")}
        open={Boolean(fullscreenImg)}
        src={fullscreenImg}
      />
    </div>
  );
};

export default InstagramGallery;
