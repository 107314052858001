import React, { lazy, Suspense, useState } from "react";
import "./index.scss";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import { CircularProgress, CssBaseline, Snackbar, Button } from "@mui/material";
import Consumer from "./pages/Consumer";
import Join from "./pages/Join";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import ProfileDetails from "./components/ProfileDetails";
import { QueryClientProvider, QueryClient } from "react-query";
import { CoursePage } from "./pages/Course";
import { useLocation } from "react-router";

const client = new QueryClient();

const LazyTrainer = lazy(() => import("./pages/Trainer"));
const LazyPrivacy = lazy(() => import("./pages/Privacy"));
const LazyTerms = lazy(() => import("./pages/Terms"));
const LazyDataDelection = lazy(() => import("./pages/DeleteData"));
const LazyCourses = lazy(() => import("./pages/Courses"));
const LazyTrainers = lazy(() => import("./pages/Trainers"));

function App() {
  const [cookies, setCookies] = useState(
    Boolean(localStorage.getItem("cookies"))
  );

  const loc = useLocation();

  return (
    <QueryClientProvider client={client}>
      <div className="App">
        {!loc.pathname.startsWith("/trainers/") && <Navbar />}
        <main>
          <Suspense
            fallback={
              <section className="min-height">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              </section>
            }
          >
            <Switch>
              <Route path="/" exact={true} component={Home} />
              <Route path="/trainer" component={LazyTrainer} />
              <Route path="/consumers" component={Consumer} />
              <Route path="/join" component={Join} />
              <Route path="/contact" component={Contact} />
              <Route path="/courses/:id" component={CoursePage} />
              <Route path="/courses" component={LazyCourses} />
              <Route path="/terms" component={LazyTerms} />
              <Route path="/privacy" component={LazyPrivacy} />
              <Route path="/trainers/:id" component={ProfileDetails} />
              <Route path="/trainers" component={LazyTrainers} />
              <Route path="/NZ" component={LazyTrainers} />
              <Route path="/data-deletion" component={LazyDataDelection} />
            </Switch>
          </Suspense>
        </main>
        <Snackbar
          message="This website uses cookies."
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          open={!cookies}
          ContentProps={{
            style: {
              minWidth: 400,
            },
          }}
          action={
            <React.Fragment>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  localStorage.setItem("cookies", "true");
                  setCookies(true);
                }}
              >
                I consent
              </Button>
            </React.Fragment>
          }
        />
        {!loc.pathname.startsWith("/trainers/") && <Footer />}
        <CssBaseline />
        <ScrollToTop />
      </div>
    </QueryClientProvider>
  );
}

export default App;
